import React from "react";
import ProductCard from "../components/UI/ProductCard";
import { useCart } from "../store/cart";
const CartPage = () => {
  const cartItems = useCart((state) => state.cartItems);
  console.log(cartItems.length);
  return (
    <div className="flex flex-col">
      <h2 className="text-3xl font-bold text-center">Your Items in cart</h2>
      <div className="flex flex-col flex-wrap items-center md:justify-center md:flex-row gap-5 mt-10">
        {cartItems.map((data) => (
          <ProductCard
            key={data.id}
            data={data}
            //   onAddToCart={() => onAddToCart(data)}
          />
        ))}
      </div>
    </div>
  );
};

export default CartPage;
