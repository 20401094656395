import React from "react";

const ProductCard = ({ data, onAddToCart }) => {
  const getData = (price) => {
    console.log(price);
  };
  return (
    <>
      <div className="w-[300px] h-auto bg-[#DFE0DC] shadow-lg text-black pb-1  rounded-lg cursor-pointer transition-all ease-in duration-100 hover:shadow-md ">
        <img
          src={data.image}
          width={400}
          height={400}
          alt=""
          className=" rounded-lg h-[250px] w-[400px]"
        />
        <p className="font-bold text-xl text-center pt-3">{data.title}</p>

        <p className="text-center font-bold py-3">&#36;{data.price}</p>

        <button
          className="bg-mainbg text-white hover:scale-95 px-5 py-2 w-full rounded-b-lg  transition-all duration-50 ease-in"
          // onClick={() => getData(data.price)}
          onClick={onAddToCart}
        >
          Add to Cart
        </button>
      </div>
    </>
  );
};

export default ProductCard;
