import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Shop from "./pages/Shop";
import Laptop from "./pages/Laptop";
import Mobile from "./pages/Mobile";
import CartPage from "./pages/CartPage";
const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/cart" element={<CartPage />} />
        {/* <Route path="/shop" element={<Shop />} />
         */}

        <Route path="/shop" element={<Shop />}>
          {/* <Route path="laptop" element={<Laptop/>} /> */}
        </Route>
        <Route path="/shop/laptop" element={<Laptop />} />
        <Route path="/shop/mobile" element={<Mobile />} />
        <Route path="/shop/headphones" element={<Mobile />} />
      </Routes>
    </>
  );
};

export default App;
