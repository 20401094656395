import React, {Suspense} from "react";
import { Link } from "react-router-dom";
import Loader from "../components/UI/Loader";
// import CategoryCard from "../components/UI/CategoryCard";
const CategoryCard = React.lazy(()=> import("../components/UI/CategoryCard"))
const Homepage = () => {
  return (
    <div>
      <h2 className="text-3xl text-center font-bold mb-10">
        Huge discounts on electronics this festive season
      </h2>

    <Suspense fallback={<Loader/>} >
    <div className="categories flex md:flex-row flex-wrap flex-col items-center gap-10 md:gap-20 justify-center">
        <Link to="/shop/laptop">
          <CategoryCard image={"images/laptop.jpg"} text={"Shop Laptop"} />
        </Link>
        <Link to="/shop/mobile">
          <CategoryCard image={"images/mobile.jpg"} text={"Shop Mobile"} />
        </Link>
        <CategoryCard
          image={"images/headphones.jpg"}
          text={"Shop Headphones"}
        />
      </div>
    </Suspense>
    </div>
  );
};

export default Homepage;
