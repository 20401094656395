import { Link } from "react-router-dom";
import { useState } from "react";
import { HiX, HiMenu } from "react-icons/hi";
import { MdAccountCircle } from "react-icons/md";
import { FaShoppingCart } from "react-icons/fa";
import { useCart } from "../../store/cart";
const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav((prev) => !prev);
  const cartItems = useCart((state) => state.cartItems);
  const cartItemsLength = cartItems.length;

  let navclasses;
  if (nav) {
    navclasses =
      "md:hidden absolute left-0  text-xl bg-mainbg w-full h-[calc(100vh-80px)] flex justify-center flex-col align-middle px-8 py-10 ";
  } else {
    navclasses =
      "hidden md:hidden absolute left-0  text-xl bg-mainbg w-full h-[calc(100vh-80px)] flex justify-center flex-col align-middle px-8 py-10 ";
  }
  return (
    <header className=" h-[80px] z-10 text-white bg-mainbg  relative md:p-9 p-4">
      <div className="px-2 flex justify-between items-center w-full h-full">
        <h1 className="text-2xl font-headingFont font-bold mr-4 sm:text-3xl">
          <Link to="/">Shop</Link>
        </h1>

        <nav className="hidden md:flex md:gap-10 ">
          <ul className="hidden md:flex navbar-list">
            <li className="p-4">
              <Link to="/">Home</Link>
            </li>
            <li className="p-4">
              <Link to="/shop">Shop</Link>
            </li>
          </ul>

          <div className="hidden md:flex md:pr-0 md:items-center">
            <Link to="/cart">
              <div className="navbar-cta cursor-pointer mr-4 bg-accent px-7 py-2 rounded-full md:flex gap-1 md:items-center  ">
                <FaShoppingCart className="inline " size={20} />
                <div className="flex items-center">
                  <span>Cart</span>
                  {cartItemsLength === 0 ? null : (
                    <div className="total ml-2 bg-white text-black h-[30px] w-[30px] rounded-full text-sm font-bold justify-center flex items-center">
                      <span>{cartItemsLength}</span>
                    </div>
                  )}
                </div>
              </div>
            </Link>
            <div className="navbar-cta ">
              <MdAccountCircle className="inline " size={23} />
              <span className="ml-[5px]">Account</span>
            </div>
          </div>
        </nav>

        <div className="md:hidden cursor-pointer" onClick={handleClick}>
          {!nav ? <HiMenu className="" size={30} /> : <HiX size={30} />}
        </div>
      </div>

      {nav && (
        // <ul className="md:hidden absolute left-0  text-xl bg-mainbg w-full h-[100vh] flex justify-center flex-col align-middle px-8 py-10 ">
        <div className={navclasses}>
          <ul>
            <li className="text-center py-2" onClick={handleClick}>
              <Link href="/">Home</Link>
            </li>
            <li className="text-center py-2" onClick={handleClick}>
              <Link href="/blog">Shop</Link>
            </li>
            <li className="text-center py-2" onClick={handleClick}>
              <Link href="/about">About</Link>
            </li>
          </ul>

          <div className=" md:hidden flex items-center flex-col w-full mt-5 gap-2">
            <Link to="/cart">
              <div className="navbar-cta mr-4 bg-accent px-7 py-2 rounded-full flex gap-2 items-center">
                <FaShoppingCart className="inline " size={20} />
                <div className="flex items-center">
                  <span>Cart</span>
                  {cartItemsLength === 0 ? null : (
                    <div className="total ml-2 bg-white text-black h-[30px] w-[30px] rounded-full text-sm font-bold justify-center flex items-center">
                      <span>{cartItemsLength}</span>
                    </div>
                  )}
                </div>
              </div>
            </Link>
            <div className="navbar-cta ">
              <MdAccountCircle className="inline " size={23} />
              <span className="ml-[5px]">Account</span>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
