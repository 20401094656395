import React, { Suspense } from "react";
import { useQuery } from "@tanstack/react-query";
import Loader from "../components/UI/Loader";
// import ProductCard from "../components/UI/ProductCard";
import { useCart } from "../store/cart";
const ProductCard = React.lazy(() => import("../components/UI/ProductCard"));

const Laptop = () => {
  const fetchLaptop = async () => {
    const res = fetch("/laptop.json");
    return (await res).json();
  };

  const addItemToCart = useCart((state) => state.addItemToCart);

  const { data, error } = useQuery(["laptops"], fetchLaptop);
  console.log(data);
  console.log("error", error);

  const onAddToCart = (data) => {
    console.log(data);
    addItemToCart(data);
  };
  return (
    <div>
      <h2 className="text-3xl font-bold text-center">Shop Laptops</h2>

      <section className="mt-10">
        <h3 className="text-2xl font-bold">Best Selling Laptops</h3>

        <Suspense fallback={<Loader />}>
          <div className="flex flex-wrap flex-col md:flex-row items-center gap-20 justify-center mt-10">
            {data &&
              data.map((data) => (
                <ProductCard
                  key={data.id}
                  data={data}
                  onAddToCart={() => onAddToCart(data)}
                />
              ))}
          </div>
        </Suspense>
      </section>
    </div>
  );
};

export default Laptop;
