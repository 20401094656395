import React from "react";
import Header from "./Header";
import Footer from "./Footer";
const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="p-6 md:p-9 md:px-11">{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
