import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Layout from './components/Layout/Layout'
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
    <BrowserRouter>
        <Layout>
          <App />
        </Layout>
    </BrowserRouter>
      </QueryClientProvider>
  </React.StrictMode>
);
