import React from "react";
import { useQuery } from "@tanstack/react-query";
import ProductCard from "../components/UI/ProductCard";

const Mobile = () => {
  const fetchMobiles = async () => {
    const res = fetch("/mobile.json");
    return (await res).json();
  };

  const { data, error } = useQuery(["mobile"], fetchMobiles);
  console.log(data);
  console.log("error", error);
  return (
    <div>
      <h2 className="text-3xl font-bold text-center">Shop Phones</h2>

      <section className="mt-10">
        <h3 className="text-2xl font-bold">Best Selling Phones</h3>

        <div className="flex flex-wrap flex-col md:flex-row items-center gap-20 justify-center mt-10">
          {data && data.map((data) => <ProductCard key={data.id} data={data} />)}
        </div>
      </section>
    </div>
  );
};

export default Mobile;
