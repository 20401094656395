import {Link} from "react-router-dom";
const Footer = () => {
  return (

      <footer className="bg-mainbg w-full h-[80px] flex items-center text-white p-6 md:p-9 md:px-11 mt-auto">
        <p className="w-full text-center">
          &copy;2022 <Link to="/">Shop</Link>
        </p>
      </footer>
   
  );
};

export default Footer;